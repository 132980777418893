import type { ReactElement } from 'react';
import styled from 'styled-components';
import Attributes from '../partials/Attributes';
import type { Title as SectionDownloadsSchema } from '../../../amplienceTypes/schemas/exported/section-downloads-schema';
import { colors, media } from '../../shared/core/styles';
import { useStaticContext } from '../../../utilities/context/static/StaticContext';

const S = {
  Wrapper: styled(Attributes)`
    color: ${colors.NERO_GREY};
    overflow: hidden;
    background-color: transparent;
  `,

  ContentBody: styled.div`
    text-align: left;
    padding: 0;
    display: inline-block;
    font-size: 13px;
    vertical-align: middle;
    width: 100%;

    @media ${media.greaterThan('sm')} {
      max-width: 100%;
    }
  `,

  BodyTitle: styled.h2`
    padding: 0;
    line-height: 0.9em;
    letter-spacing: 0;
    text-transform: uppercase;
    font-weight: 900;
    margin: 2em 0 1em;
    font-size: 18px;

    @media ${media.greaterThan('sm')} {
      font-size: 20px;
    }
  `,

  DownloadGroup: styled.div`
    margin-bottom: 20px;
  `,

  Subtitle: styled.h3`
    font-weight: 700;
    line-height: 1em;
    padding: 0;
    font-size: 14px;
    margin: 0 0 0.5em;

    @media ${media.greaterThan('sm')} {
      font-size: 16px;
    }
  `,

  DownloadBody: styled.div`
    padding: 4px 0;
    margin-bottom: 4px;
    line-height: 1.2;
  `,

  Link: styled.a`
    display: inline-block;
    text-decoration: underline 2px solid;
    font-weight: bold;
    margin-right: 4px;
    font-size: 14px;
    color: currentcolor;
  `,

  LinkDesc: styled.span`
    text-align: left;
    line-height: 1.2;
    display: inline-block;
    vertical-align: bottom;
    font-size: 11px;
  `,
};

export const SectionDownloads = ({
  title,
  downloadList,
  attributes,
}: SectionDownloadsSchema): ReactElement => {
  const { accessibilityLabels } = useStaticContext();

  return (
    <S.Wrapper
      {...attributes}
      className="contentBlock js-contentBlock"
      ariaRole={accessibilityLabels?.hpSectionDownloads?.role}
      ariaLabel={accessibilityLabels?.hpSectionDownloads?.label}
    >
      <div className="contentBlock-body">
        <S.ContentBody>
          {title && (
            // eslint-disable-next-line react/no-danger
            <S.BodyTitle dangerouslySetInnerHTML={{ __html: title }} />
          )}

          {downloadList && (
            <div className="contentBlock-body-downloads">
              {downloadList.map((download, i) => (
                <S.DownloadGroup key={i}>
                  {download.subTitle && (
                    <S.Subtitle
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{ __html: download.subTitle }}
                    />
                  )}

                  {download.downloads?.map((subDownload, j) => (
                    <S.DownloadBody key={j}>
                      {subDownload.url && (
                        <S.Link href={subDownload.url}>{subDownload.text}</S.Link>
                      )}
                      {subDownload.meta && <S.LinkDesc>({subDownload.meta})</S.LinkDesc>}
                    </S.DownloadBody>
                  ))}
                </S.DownloadGroup>
              ))}
            </div>
          )}
        </S.ContentBody>
      </div>
    </S.Wrapper>
  );
};
