import type { ReactElement } from 'react';
import type { HTML } from '../../../amplienceTypes/schemas/exported/html-code-schema';

export const HtmlCode = ({ inline }: HTML): ReactElement => {
  const htmlString = (inline?.htmlEditor?.html as string).replace(
    /(["'`])\/\/campaign\.g-star\.com/g,
    '$1https://campaign.g-star.com'
  );

  // eslint-disable-next-line react/no-danger
  return htmlString ? <div dangerouslySetInnerHTML={{ __html: htmlString }} /> : <></>;
};
