import type { ReactElement } from 'react';
import type { HTMLText } from '../../../amplienceTypes/schemas/exported/html-text-schema';
import Attributes from '../partials/Attributes';

export const HtmlText = ({ content, attributes }: HTMLText): ReactElement =>
  content?.htmlEditor?.html ? (
    <Attributes
      {...attributes}
      additionalClassNames="contentBlock js-contentBlock contentBlock--longCopy"
    >
      <div className="contentBlock-body js-contentBlock-body">
        <div className="contentBlock-bodyAlign">
          <div
            className="contentBlock-body-text js-contentBlock-body-text"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: (content?.htmlEditor?.html as string) || '' }}
            suppressHydrationWarning
          />
        </div>
      </div>
    </Attributes>
  ) : (
    <></>
  );
